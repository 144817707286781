<template>
  <CFooter :fixed="false">
    <div>
      <a href="https://somosorderly.com" target="_blank">Orderly</a>
      <span class="ml-1">&copy; {{new Date().getFullYear()}} Orderly SAPI de CV</span>
    </div>
    <div class="mfs-auto">
      <a href="https://somosorderly.com/lets-talk-business/asistencia-y-ayuda-facturador" target="_blank">ASISTENCIA Y AYUDA</a> | 
      <a href="https://somosorderly.com/terminos-y-condiciones" target="_blank">TÉRMINOS Y CONDICIONES</a> | 
      <a href="https://somosorderly.com/aviso-de-privacidad" target="_blank">AVISO DE PRIVACIDAD</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
