import axios from 'axios'

export default {
    getAccounts: (status) => axios.get('accounts/'+status),
    getAccount: (account_id) => axios.get('accounts/'+account_id+'/show'),
    getPurchases: (account_id) => axios.get('accounts/'+account_id+'/purchases'),
    storePurchase: (data) => axios.post('accounts/purchase', data),
    activeAccount: (account_id) => axios.post('accounts/active', {account_id: account_id}),
    deactiveAccount: (account_id) => axios.post('accounts/deactive', {account_id: account_id}),
    deleteAccount: (account_id) => axios.post('accounts/delete', {account_id: account_id}),
    getInfo: () => axios.get('account/general-info'),
    getSubscription: () => axios.get('account/subscription'),
    subscriptionBuy: (data) => axios.post('account/subscription/buy',data),    
    subscriptionCancel: () => axios.post('account/subscription/cancel'),   
    deleteCard: () => axios.delete('account/card/delete'),
    getCard: () => axios.get('account/card'),
    storeCard: (data) => axios.post('account/card/store',data),
    getBills: () => axios.get('account/bills'),
    downloadXML: (id) => axios.get('account/bills/'+id+'/download-xml').then(function(response){
        if(response.type == "success"){
            window.open(axios.defaults.baseURL+"/s3?file="+response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),
    downloadPDF: (id) => axios.get('account/bills/'+id+'/download-pdf').then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),
    setInfo: (data) => axios.put('account/general-info', data),
    getAccountDetails: () => axios.get('account/details'),
    getAnalytics: () => axios.get('account/analytics'),
    getOnboarding: () => axios.get('account/onboarding'),
    getFiscalInfo: () => axios.get('account/fiscal-info'),
    setFiscalInfo: (data) => axios.put('account/fiscal-info', data),
    getPartners: () => axios.get('account/partners'),
    hasPartners: () => axios.get('account/has-partners'),
    getOwnerAccounts: () => axios.get('account/owners'),
    hasOwnerAccounts: () => axios.get('account/has-owners'),
    changeLogo: (data) => axios.post('account/logo', data, {headers: {"Content-Type": "multipart/form-data",}}),
    setPassword: (data) => axios.post('users/update-password', data),
}